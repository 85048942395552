import type { GetServerSideProps, NextPage } from 'next';
import { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { registerPosthogEvent } from '../helpers/postHogEventHelpers';
import { EVENT_NAME } from '../utils/constants/postHogConstants';
import { UTMQueryInterface } from '../types/types';
import { AuthModal } from '../components/Modals/FirebaseAuthModal/AuthModal';

export const getServerSideProps: GetServerSideProps = async (context) => {
    return {
        props: {
            utmParams: context.query
        }
    };
};

interface LandingPageProps {
    utmParams: UTMQueryInterface;
}

const LandingPage: NextPage<LandingPageProps> = ({ utmParams }) => {
    const { user, group, project } = useAuth();

    useEffect(() => {
        registerPosthogEvent(EVENT_NAME.pageLoad, {
            pageName: 'landingPage',
            user,
            group,
            project,
            utmParams
        });
    // ignore warning missing dependency. Resolving results in unnecessary re-renders
    }, []); // eslint-disable-line

    

    return <AuthModal />;
};

export default LandingPage;
